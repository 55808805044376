
import { defineComponent, computed, PropType } from 'vue'
import { RouteParamsRaw, useRouter } from 'vue-router'
import { format } from 'date-fns'
import { SearchDeviation } from '@/types/search-deviation'

export default defineComponent({
  name: 'DeviationSearchWarningTable',

  props: {
    deviationType: String,
    deviations: {
      type: Object as PropType<SearchDeviation>,
      required: true,
    },
    mode: String,
    uuid: String,
  },

  setup(props) {
    const router = useRouter()

    const searchDeviationColumns = [
      {
        align: 'left',
        name: 'type',
        required: true,
        label: 'Avvikelsetyp',
        field: 'type',
        sortable: true,
      },
      {
        align: 'left',
        name: 'from',
        required: true,
        label: 'Från',
        field: 'from',
        sortable: true,
      },
      {
        align: 'left',
        name: 'to',
        required: true,
        label: 'Till',
        field: 'to',
        sortable: true,
      },
      {
        align: 'left',
        name: 'avg',
        required: true,
        label: 'Avg.',
        field: 'avg',
        format: (x: string) => format(new Date(x), 'HH:mm'),
        sortable: true,
      },
      {
        align: 'left',
        name: 'ank',
        required: true,
        label: 'Ank.',
        field: 'ank',
        format: (x: string) => format(new Date(x), 'HH:mm'),
        sortable: true,
      },
      {
        align: 'right',
        name: 'eventUuid',
        required: true,
        label: 'Händelse',
        field: 'eventUuid',
        sortable: true,
      },
    ]

    function removeSameDeviationIfUpdate(x: { uuid: string }) {
      return props.mode === 'update' ? x.uuid !== props.uuid : true
    }

    const searchDeviations = computed(() => {
      return [
        ...props.deviations.deviationCancel
          .map((x) => ({
            ...x,
            _type: 'Inställt tåg',
          }))
          .filter(removeSameDeviationIfUpdate),
        ...props.deviations.deviationVehicle
          .map((x) => ({
            ...x,
            _type: 'Fordonsavvikelse',
          }))
          .filter(removeSameDeviationIfUpdate),
        ...props.deviations.deviationCrew
          .map((x) => ({
            ...x,
            _type: 'Bemanningsavvikelse',
          }))
          .filter(removeSameDeviationIfUpdate),
      ].map((x) => ({
        type: x._type,
        from: x.from,
        to: x.to,
        avg: x.departure,
        ank: x.arrival,
        eventUuid: x.eventUuid,
      }))
    })

    const isSearchDeviations = computed(() => {
      return searchDeviations.value.length
    })

    function openSearchDeviationEventNewTab(params: RouteParamsRaw) {
      const route = router.resolve({
        name: 'event',
        params,
      })

      window.open(route.href, '_blank')
    }

    return {
      openSearchDeviationEventNewTab,
      searchDeviations,
      isSearchDeviations,
      searchDeviationColumns,
    }
  },
})


import {
  required as requiredRule,
  requiredNotNegative,
  requiredNotNull,
  validDateRule,
} from '@/common/formValidationRules'
import { useSearchTrain } from '@/composable/useSearchTrain'
import { format, isBefore, startOfMinute, addDays } from 'date-fns'
import {
  defineComponent,
  computed,
  watch,
  ref,
  PropType,
  onBeforeMount,
  onMounted,
} from 'vue'
import AppFieldDate from '@/components/AppFieldDate.vue'
import AppFieldSelect from '@/components/AppFieldSelect.vue'
import orderBy from 'lodash.orderby'
import DeviationSearchWarningTable from '@/components/deviation/DeviationSearchWarningTable.vue'
import DeviationSearchPathTable from '@/components/deviation/DeviationSearchPathTable.vue'
import QInput from 'quasar/src/components/input/QInput.js';

interface ModelValue {
  departureDate: Date
  departure: Date
  arrival: Date
  advertised: number
  from: string
  to: string
  delayAdvertisedTime: Date
  delayLocation: string
  delayType: string
  delayMinutes: number
  delayReason: string
  delayReasonDescription: string
  uuid: string
}

export default defineComponent({
  name: 'DeviationSearchTrainInput',

  components: {
    AppFieldDate,
    AppFieldSelect,
    DeviationSearchWarningTable,
    DeviationSearchPathTable,
  },

  props: {
    deviationType: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object as PropType<ModelValue>,
      required: true,
    },
    skipSearchTrainComposition: {
      type: Boolean,
      default: () => false,
    },
  },

  emits: ['update:modelValue', 'first-search'],

  setup(props, { emit }) {
    const {
      search: searchTrain,
      data: searchTrainData,
      loading: searchingTrain,
      placesDeparture,
      placesArrival,
      deviations,
    } = useSearchTrain()
    const isFirstSearch = ref(true)
    const departureDate = ref(format(new Date(), 'yyyy-MM-dd'))
    const departureTime = ref(format(new Date(), 'HH:mm'))
    const arrivalTime = ref(format(new Date(), 'HH:mm'))
    const delayTime = ref(format(new Date(), 'HH:mm'))
    const delayLocation = ref('')
    const trainNumberInputRef = ref<QInput>()
    const searchTrainNumberState = ref()
    const isFocused = ref(false)
    const model = ref(Object.assign({}, props.modelValue))
    searchTrainNumberState.value = model.value.advertised
    onMounted(() => {
      setTimeout(() => {
        if (!trainNumberInputRef.value) return
        trainNumberInputRef.value.focus()
      }, 100)
    })

    onBeforeMount(() => {
      departureDate.value = format(
        new Date(props.modelValue.departureDate),
        'yyyy-MM-dd'
      )
      if (['update', 'create-proposal'].includes(props.mode)) {
        if (
          ['deviationVehicle', 'deviationCancel'].includes(props.deviationType)
        ) {
          departureTime.value = format(
            new Date(props.modelValue.departure),
            'HH:mm'
          )
          arrivalTime.value = format(
            new Date(props.modelValue.arrival),
            'HH:mm'
          )
        }

        if (props.deviationType === 'deviationDelay') {
          delayTime.value = format(
            new Date(props.modelValue.delayAdvertisedTime),
            'HH:mm'
          )
          delayLocation.value = `(${props.modelValue.delayType}) ${props.modelValue.delayLocation}`
        }
      }
    })

    watch(
      () => model.value,
      (value) => {
        emit(
          'update:modelValue',
          Object.assign(
            {},
            {
              ...props.modelValue,
              advertised: value.advertised,
              departureDate: value.departureDate,
              arrival: value.arrival,
              departure: value.departure,
              from: value.from,
              to: value.to,
              delayAdvertisedTime: value.delayAdvertisedTime,
              delayLocation: value.delayLocation,
              delayType: value.delayType,
              delayMinutes: value.delayMinutes,
              delayReason: value.delayReason,
              delayReasonDescription: value.delayReasonDescription,
            }
          )
        )
      },
      { deep: true }
    )

    watch(
      () => searchTrainNumberState.value,
      (value) => {
        if (value !== model.value.advertised) {
          model.value.advertised = value
          onFindTrain(value)
        }
      },
      { immediate: false }
    )

    watch(searchTrainData, () => {
      if (isFirstSearch.value && ['update'].includes(props.mode)) {
        isFirstSearch.value = false
        return
      }

      if (isFirstSearch.value && ['create-proposal'].includes(props.mode)) {
        setDepartureTime(model.value.from)
        setArrivalTime(model.value.to)
        return
      }

      model.value.delayAdvertisedTime = new Date()
      if (placesDeparture.value.length) {
        model.value.from = placesDeparture.value[0].location
      } else {
        model.value.from = ''
      }

      if (placesArrival.value.length) {
        model.value.to =
          placesArrival.value[placesArrival.value.length - 1].location
      } else {
        model.value.to = ''
      }

      model.value.departure = new Date()
      model.value.arrival = new Date()

      model.value.delayType = 'ank'
      model.value.delayMinutes = 0
      model.value.delayLocation = ''
      model.value.delayReason = ''
      model.value.delayReasonDescription = ''

      departureTime.value = format(new Date(), 'HH:mm')
      arrivalTime.value = format(new Date(), 'HH:mm')
      delayTime.value = format(new Date(), 'HH:mm')
      delayLocation.value = ''
    })

    function setDepartureTime(value: string) {
      if (!placesDeparture.value.length) return
      const placeDeparture = placesDeparture.value.find(
        (place) => place.location === value
      )
      if (!placeDeparture) return
      departureTime.value = format(
        new Date(placeDeparture.advertisedTimeAtLocation),
        'HH:mm'
      )

      if (
        !filteredArrivalPlaces.value.some(
          (place) => place.location === model.value.to
        )
      ) {
        model.value.to = ''
      }
    }

    function setArrivalTime(value: string) {
      if (!placesArrival.value.length) return
      const placeArrival = placesArrival.value.find(
        (place) => place.location === value
      )
      if (!placeArrival) return
      arrivalTime.value = format(
        new Date(placeArrival.advertisedTimeAtLocation),
        'HH:mm'
      )
    }

    watch(() => model.value.from, setDepartureTime)

    watch(() => model.value.to, setArrivalTime)

    watch(
      () => model.value.delayLocation,
      (value) => {
        if (props.deviationType === 'deviationDelay') return
        if (!delayPlaces.value.length) return
        const location = delayPlaces.value.find(
          (place) => place.location === value
        )

        if (!location) return
        console.log(location)
        delayTime.value = format(
          new Date(location.advertisedTimeAtLocation),
          'HH:mm'
        )
        model.value.delayMinutes = location.delay

        const locationType = delayPlaces.value.find(
          (place) =>
            place.location === value &&
            place.activityType === model.value.delayType
        )
        if (locationType) {
          model.value.delayReason = locationType?.delayReason || ''
          model.value.delayReasonDescription =
            locationType?.delayReasonDescription || ''
        }
      }
    )

    watch(delayLocation, (v) => {
      if (v) {
        const [delayTypeUnparsed, delayLocation] = v.split(' ')
        const delayType = delayTypeUnparsed.replace('(', '').replace(')', '')
        const location = delayPlaces.value.find(
          (place) =>
            place.location === delayLocation && place.activityType === delayType
        )
        model.value.delayType = delayType
        model.value.delayLocation = delayLocation

        if (location) {
          model.value.delayReason = location?.delayReason || ''
          model.value.delayReasonDescription =
            location?.delayReasonDescription || ''
          model.value.delayMinutes = location.delay
          model.value.delayAdvertisedTime = location.advertisedTimeAtLocation
          delayTime.value = format(
            new Date(location.advertisedTimeAtLocation),
            'HH:mm'
          )
        }
      } else {
        model.value.delayType = ''
        model.value.delayLocation = ''
        model.value.delayMinutes = 0
        model.value.delayReason = ''
        model.value.delayReasonDescription = ''
      }
    })

    watch(() => model.value.arrival, shouldAddDayToArrival)
    watch(() => model.value.departure, shouldAddDayToArrival)

    watch(departureTime, (v) => {
      model.value.departure = new Date(`${departureDate.value} ${v}`)
    })
    watch(arrivalTime, (v) => {
      model.value.arrival = new Date(`${departureDate.value} ${v}`)
    })

    watch(departureDate, () => {
      model.value.departure = new Date(
        `${departureDate.value} ${departureTime.value}`
      )
      model.value.arrival = new Date(
        `${departureDate.value} ${arrivalTime.value}`
      )
      model.value.departureDate = new Date(departureDate.value)

      onFindTrain(model.value.advertised)
    })

    if (['create-proposal'].includes(props.mode)) {
      onFindTrain(model.value.advertised)
    }

    async function onFindTrain(value: number) {
      if (
        ['create'].includes(props.mode) ||
        (!isFirstSearch.value &&
          ['update', 'create-proposal'].includes(props.mode))
      ) {
        model.value.from = ''
        model.value.to = ''

        arrivalTime.value = format(new Date(), 'HH:mm')
        departureTime.value = format(new Date(), 'HH:mm')
      }

      if (typeof model.value.advertised !== 'number') {
        model.value.to = ''
        model.value.from = ''
        return
      }
      const params = {
        tnr: value,
        date: departureDate.value,
      }
      await searchTrain(params, props.skipSearchTrainComposition).catch(
        (err) => {
          if (typeof parseInt(err.message, 10) === 'number') {
            model.value.advertised = parseInt(err.message, 10)
            onFindTrain(model.value.advertised)
          }
        }
      )
    }

    const filteredDeparturePlaces = computed(() => {
      if (!placesDeparture.value.length) return []
      return placesDeparture.value
    })

    const filteredArrivalPlaces = computed(() => {
      if (!placesArrival.value.length) return []
      const sliceIndex = placesArrival.value.findIndex(
        (place) => place.location === model.value.from
      )

      return placesArrival.value.slice(sliceIndex + 1)
    })

    const delayPlaces = computed(() => {
      if (!placesDeparture.value.length || !placesArrival.value.length)
        return []
      const places = [...placesDeparture.value, ...placesArrival.value]
      return orderBy(places, ['advertisedTimeAtLocation'], ['asc']).map(
        (place) => ({
          ...place,
          _delayLabel: `(${place.activityType}) ${place.location}`,
        })
      )
    })

    function shouldAddDayToArrival() {
      if (model.value.arrival && model.value.departure)
        if (
          isBefore(
            startOfMinute(new Date(model.value.arrival)),
            startOfMinute(new Date(model.value.departure))
          )
        ) {
          model.value.arrival = addDays(new Date(model.value.arrival), 1)
        }
    }

    return {
      delayLocation,
      delayTime,
      departureDate,
      departureTime,
      arrivalTime,
      searchingTrain,
      filteredArrivalPlaces,
      filteredDeparturePlaces,
      searchTrainData,
      requiredRule,
      requiredNotNull,
      validDateRule,
      model,
      delayPlaces,
      onFindTrain,
      requiredNotNegative,
      deviations,
      trainNumberInputRef,
      searchTrainNumberState,
      isFocused,
    }
  },
})


import { SearchTrain } from '@/types/search-train'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'DeviationSearchPathTable',

  props: {
    searchTrainData: {
      type: Object as PropType<SearchTrain>,
      required: true,
    },
  },

  setup() {
    const trainPathColumns = [
      {
        align: 'left',
        name: 'trainNumber',
        required: true,
        label: 'Tågid.Core',
        field: 'trainNumber',
        sortable: true,
      },
      {
        align: 'left',
        name: 'publicNumber',
        required: true,
        label: 'Annonserat',
        field: 'publicNumber',
        sortable: true,
      },
      {
        align: 'left',
        name: 'operativttagnummer',
        required: true,
        label: 'OTN',
        field: 'operativttagnummer',
        sortable: true,
      },
      {
        align: 'left',
        name: 'from',
        required: true,
        label: 'Från',
        field: 'from',
        sortable: true,
      },
      {
        align: 'left',
        name: 'to',
        required: true,
        label: 'Till',
        field: 'to',
        sortable: true,
      },
    ]

    return {
      trainPathColumns,
    }
  },
})
